import { Component } from 'react';

import { login } from 'api';

class LoginHandler extends Component {

  async componentDidMount() {
    const code = window.location.search.substring(6);
    const loginResult = await login(code);

    // success
    if ('access_token' in loginResult) {
      const accessToken = loginResult.access_token;
      // const accessTokenSecret = loginResult.access_token_secret;
      // const refreshToken = loginResult.refresh_token;

      window.opener.sessionStorage.clear();
      window.opener.sessionStorage.setItem('access_token', accessToken);
      // window.opener.sessionStorage.setItem('access_token_secret', accessTokenSecret);
      // window.opener.sessionStorage.setItem('refresh_token', refreshToken);
    }
    // failure
    else {
      alert('예기치 않은 문제가 발생하였습니다.');
    }
      
    window.opener.location.replace('/');
    window.close();
  }

  render() {
    return null;
  }
}

export default LoginHandler;